@font-face {
  font-family: "SYEL";
  src: url('https://webfiles.hncdcenter.com/files/SourceHanSansK-ExtraLight.c369591c.ttf');
}
@font-face {
  font-family: "SYH";
  src: url('https://webfiles.hncdcenter.com/files/SourceHanSansK-Heavy.d60fb691.ttf');
}
/*@font-face {font-family: "SYL";*/
/*  src: url('http://webfiles.hncdcenter.com/files/SourceHanSansK-Light.abf23f66.ttf');*/
/*}*/
@font-face {
  font-family: "SYM";
  src: url('https://webfiles.hncdcenter.com/files/SourceHanSansK-Medium.29ac0f4a.ttf');
}
@font-face {
  font-family: "SYN";
  src: url('https://webfiles.hncdcenter.com/files/SourceHanSansK-Normal.26874cbb.ttf');
}
@font-face {
  font-family: "SYR";
  src: url('https://webfiles.hncdcenter.com/files/SourceHanSansK-Regular.f38cddb7.ttf');
}
.SYEL {
  font-family: "SYEL" !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.SYH {
  font-family: "SYH" !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.SYL {
  font-family: 'SourceHanSansCN-light' !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.SYM {
  font-family: "SYM" !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.SYN {
  font-family: "SYN" !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.SYR {
  font-family: "SYR" !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-slide > div > div {
  display: flex!important;
  justify-content: flex-start;
}
.ant-layout {
  background-color: #fff !important;
}
